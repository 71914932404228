import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "nationality",
    type: "text",
    sortable: true,
    searchable: true,
  },
  // {
  //   name: "address",
  //   type: "text",
  //   sortable: true,
  //   searchable: true,
  // },
  {
    name: "primary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "secondary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "email",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "dob",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "policy",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "policies.name",
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List({ title = "customers" }) {
  return <ResourceListPage url="customers" columns={columns} title={title} />;
}
