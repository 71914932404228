import React from "react";
import { Switch, Route } from "react-router-dom";
import SoftwareLogo from "../../logo.png";
import MainDashboard from "commons/components/MainDashboard";

import Home from "commons/pages/base/Home";
import ChangePassword from "commons/pages/base/ChangePassword";
import BaseSettings from "commons/pages/base/BaseSettings";
import LanguageList from "commons/pages/languages/List";
import LanguageSingle from "commons/pages/languages/Single";
import PrintTemplateList from "commons/pages/print-templates/List";
import PrintTemplateSingle from "commons/pages/print-templates/Single";
import Translations from "commons/pages/translations/List";

import BalloonList from "../balloons/List";
import BalloonSingle from "../balloons/Single";
// import BalloonRidesList from "../balloon-rides/List";
// import BalloonRidesSingle from "../balloon-rides/Single";
import CarList from "../cars/List";
import CarSingle from "../cars/Single";
import ShipList from "../ships/List";
import ShipSingle from "../ships/Single";
import CruiseList from "../cruises/List";
import CruiseSingle from "../cruises/Single";
import FileList from "../files/List";
import FileSingle from "../files/Single";
import CustomerList from "../customers/List";
// import CustomerSingle from "../customers/Controller";
import BusinessPartnerList from "../business-partners/List";
import BusinessPartnerSingle from "../business-partners/Controller";
// import CarRentalsList from "../car-rentals/List";
// import CarRentalsSingle from "../car-rentals/Single";
import CruiseCabinsList from "../cruise-cabins/List";
import CruiseCabinsSingle from "../cruise-cabins/Single";
import FacilitySingle from "../facilities/Controller";
import SaleSingle from "../sales/Single";
import PurchaseSingle from "../purchases/Single";

import CustomerController from "commons/pages/customers/Controller";
import SaleList from "commons/pages/sales/List";
import PurchaseList from "commons/pages/purchases/List";
import TransferList from "commons/pages/transfers/List";
import TransferSingle from "commons/pages/transfers/Single";
import SupplierList from "commons/pages/suppliers/List";
import SupplierSingle from "commons/pages/suppliers/Controller";
import ContractorList from "commons/pages/contractors/List";
import ContractorSingle from "commons/pages/contractors/Controller";

import ProductList from "commons/pages/products/List";
import ProductSingle from "commons/pages/products/Single";
import ServiceList from "commons/pages/services/List";
import ServiceSingle from "commons/pages/services/Single";
import CategoryList from "commons/pages/categories/List";
import CategorySingle from "commons/pages/categories/Single";
import PolicyList from "commons/pages/policies/List";
import PolicySingle from "commons/pages/policies/Controller";
import ProjectList from "commons/pages/projects/List";
import ProjectSingle from "commons/pages/projects/Single";

import CurrencyList from "commons/pages/currencies/List";
import CurrencySingle from "commons/pages/currencies/Single";
import AccountList from "commons/pages/accounts/List";
import AccountSingle from "commons/pages/accounts/Single";
import AccountsManager from "commons/pages/accounts/AccountsManager";
import TransactionList from "commons/pages/transactions/List";
import TransactionSingle from "commons/pages/transactions/Single";
import AssetGroupList from "commons/pages/asset-groups/List";
import AssetGroupSingle from "commons/pages/asset-groups/Single";
import AssetList from "commons/pages/assets/List";
import AssetSingle from "commons/pages/assets/Single";
import DiscountList from "commons/pages/discounts/List";
import DiscountSingle from "commons/pages/discounts/Single";
import TaxList from "commons/pages/taxes/List";
import TaxSingle from "commons/pages/taxes/Single";

import ContactList from "commons/pages/contacts/List";
import ContactSingle from "commons/pages/contacts/Single";
import FormTemplateList from "commons/pages/form-templates/List";
import TemplateSingle from "commons/pages/form-templates/Single";
import FormList from "commons/pages/forms/List";
import FormSingle from "commons/pages/forms/Single";
import AttachmentList from "commons/pages/attachments/List";
import AttachmentSingle from "commons/pages/attachments/Single";
import MessageManager from "commons/pages/messages/Manager";
import MessageSingle from "commons/pages/messages/Single";
import TaskList from "commons/pages/tasks/List";
import TaskSingle from "commons/pages/tasks/Single";

import FacilityList from "commons/pages/facilities/List";
// import FacilitySingle from "commons/pages/facilities/Controller";
import AccessGroupList from "commons/pages/access-groups/List";
import AccessGroupSingle from "commons/pages/access-groups/Single";
import UserList from "commons/pages/users/List";
import UserSingle from "commons/pages/users/Single";
import RoleList from "commons/pages/roles/List";
import RoleSingle from "commons/pages/roles/Single";

import EmployeeList from "commons/pages/employees/List";
import EmployeeSingle from "commons/pages/employees/Controller";

import ReportsIndex from "../reports/ReportsIndex";
import FilesReport from "../reports/FilesReport";
import CruisesReport from "../reports/CruisesReport";
import CarMovementReport from "../reports/CarMovementReport";
import CarsReport from "../reports/CarsReport";
import BalloonsReport from "../reports/BalloonsReport";

import SalesByOperation from "commons/pages/reports/sales/SalesByOperation";
import SalesByProduct from "commons/pages/reports/sales/SalesByProduct";
import SalesByCustomer from "commons/pages/reports/sales/SalesByCustomer";
import SalesByCategory from "commons/pages/reports/sales/SalesByCategory";
import SalesByUser from "commons/pages/reports/sales/SalesByUser";
import PurchasesByOperation from "commons/pages/reports/purchases/PurchasesByOperation";
import PurchasesByProduct from "commons/pages/reports/purchases/PurchasesByProduct";
import PurchasesBySupplier from "commons/pages/reports/purchases/PurchasesBySupplier";
import PurchasesByCategory from "commons/pages/reports/purchases/PurchasesByCategory";
import PurchasesByUser from "commons/pages/reports/purchases/PurchasesByUser";

import CustomersReport from "commons/pages/reports/persons/CustomersReport";
import SuppliersReport from "commons/pages/reports/persons/SuppliersReport";
import ContractorsReport from "commons/pages/reports/persons/ContractorsReport";
import EmployeesReport from "commons/pages/reports/persons/EmployeesReport";

import FinanceOverview from "commons/pages/reports/accounting/FinanceOverview";
import AccountsBalance from "commons/pages/reports/accounting/AccountsBalance";
import CurrenciesBalance from "commons/pages/reports/accounting/CurrenciesBalance";
import TransactionsReport from "commons/pages/reports/accounting/TransactionsReport";
import AccountingReports from "commons/pages/reports/accounting/AccountingReports";
import AccountingTemplateList from "commons/pages/accounting-templates/List";
import AccountingTemplateSingle from "commons/pages/accounting-templates/Single";

import OverviewReport from "commons/pages/reports/misc/OverviewReport";
import AuditReport from "commons/pages/reports/misc/AuditReport";
import OngoingServicesReport from "commons/pages/reports/misc/OngoingServices";
import EndingServicesReport from "commons/pages/reports/misc/EndingServices";
import BalanceStatement from "commons/pages/customers/BalanceStatement";

const empQuery = { permanent: true };
// const empQualifiedQuery = { "employees.permanent": true };

export default function Dashboard({ companyName = "", logo, ...props }) {
  return (
    <MainDashboard
      SoftwareLogo={SoftwareLogo}
      companyName={companyName}
      messages
      cars
      {...props}
    >
      <Switch>
        <Route path="/s/balloons/:id" component={BalloonSingle} />
        <Route path="/s/balloons" component={BalloonList} />
        <Route path="/s/cars/:id" component={CarSingle} />
        <Route path="/s/cars" component={CarList} />
        <Route path="/s/ships/:id" component={ShipSingle} />
        <Route path="/s/ships" component={ShipList} />
        <Route path="/s/cruises/:id" component={CruiseSingle} />
        <Route path="/s/cruises" component={CruiseList} />
        <Route path="/s/files/:id" component={FileSingle} />
        <Route path="/s/files" component={FileList} />
        <Route
          path="/s/business-partners/:id"
          component={BusinessPartnerSingle}
        />
        <Route path="/s/business-partners" component={BusinessPartnerList} />
        {/* <Route path="/s/car-rentals/:id" component={CarRentalsSingle} />
        <Route path="/s/car-rentals" component={CarRentalsList} />
        <Route path="/s/balloon-rides/:id" component={BalloonRidesSingle} />
        <Route path="/s/balloon-rides" component={BalloonRidesList} /> */}
        <Route path="/s/cruise-cabins/:id" component={CruiseCabinsSingle} />
        <Route path="/s/cruise-cabins" component={CruiseCabinsList} />

        <Route path="/s/sales/:id" component={SaleSingle} />
        <Route path="/s/sales" component={SaleList} />
        <Route path="/s/purchases/:id" component={PurchaseSingle} />
        <Route path="/s/purchases" component={PurchaseList} />
        <Route path="/s/transfers/:id" component={TransferSingle} />
        <Route path="/s/transfers" component={TransferList} />
        <Route
          path="/s/customers/:id/balance-statement"
          component={BalanceStatement}
        />
        <Route path="/s/suppliers/:id/balance-statement">
          <BalanceStatement
            base="suppliers"
            field="supplier_id"
            operation="purchases"
            payments_service="supplier-payments"
          />
        </Route>
        <Route path="/s/customers/:id">
          <CustomerController nationality />
        </Route>
        <Route path="/s/customers" component={CustomerList} />
        <Route path="/s/suppliers/:id" component={SupplierSingle} />
        <Route path="/s/suppliers" component={SupplierList} />
        <Route path="/s/contractors/:id" component={ContractorSingle} />
        <Route path="/s/contractors" component={ContractorList} />

        <Route path="/s/products/:id" component={ProductSingle} />
        <Route path="/s/products" component={ProductList} />
        <Route path="/s/services/:id">
          <ServiceSingle extras={["CAR_RENTAL", "BALLOON_RIDE"]} />
        </Route>
        <Route path="/s/services" component={ServiceList}>
          <ServiceList extras={["CAR_RENTAL", "BALLOON_RIDE"]} />
        </Route>
        <Route path="/s/categories/:id" component={CategorySingle} />
        <Route path="/s/categories" component={CategoryList} />
        <Route path="/s/policies/:id" component={PolicySingle} />
        <Route path="/s/policies" component={PolicyList} />
        <Route path="/s/projects/:id" component={ProjectSingle} />
        <Route path="/s/projects" component={ProjectList} />

        <Route path="/s/currencies/:id" component={CurrencySingle} />
        <Route path="/s/currencies" component={CurrencyList} />
        <Route path="/s/accounts/:id" component={AccountSingle} />
        <Route path="/s/accounts" component={AccountList} />
        <Route path="/s/accounts-manager" component={AccountsManager} />
        {/* <Route path="/s/accounts" component={AccountsManager} /> */}
        <Route path="/s/transactions/:id">
          <TransactionSingle currency />
        </Route>
        <Route path="/s/transactions" component={TransactionList} />
        <Route path="/s/asset-groups/:id" component={AssetGroupSingle} />
        <Route path="/s/asset-groups" component={AssetGroupList} />
        <Route path="/s/assets/:id" component={AssetSingle} />
        <Route path="/s/assets" component={AssetList} />
        <Route path="/s/discounts/:id" component={DiscountSingle} />
        <Route path="/s/discounts" component={DiscountList} />
        <Route path="/s/taxes/:id" component={TaxSingle} />
        <Route path="/s/taxes" component={TaxList} />

        <Route path="/s/employees/:id" component={EmployeeSingle} />
        <Route path="/s/employees">
          <EmployeeList query={empQuery} />
        </Route>

        <Route path="/s/contacts/:id" component={ContactSingle} />
        <Route path="/s/contacts" component={ContactList} />
        <Route path="/s/form-templates/:id" component={TemplateSingle} />
        <Route path="/s/form-templates" component={FormTemplateList} />
        <Route path="/s/forms/:id" component={FormSingle} />
        <Route path="/s/forms" component={FormList} />
        <Route path="/s/attachments/:id" component={AttachmentSingle} />
        <Route path="/s/attachments" component={AttachmentList} />
        <Route path="/s/messages/:id" component={MessageSingle} />
        <Route path="/s/messages" component={MessageManager} />
        <Route path="/s/tasks/:id" component={TaskSingle} />
        <Route path="/s/tasks" component={TaskList} />
        <Route path="/s/my-tasks/:id">
          <TaskSingle base="my-tasks" />
        </Route>
        <Route path="/s/my-tasks">
          <TaskList url="my-tasks" />
        </Route>

        {/* <Route path="/s/reports/tasks" component={TasksReport} /> */}
        {/* <Route path="/s/reports/forms" component={FormsReport} /> */}
        <Route
          path="/s/reports/sales-by-operation"
          component={SalesByOperation}
        />
        <Route path="/s/reports/sales-by-product" component={SalesByProduct} />
        <Route
          path="/s/reports/sales-by-category"
          component={SalesByCategory}
        />
        <Route
          path="/s/reports/sales-by-customer"
          component={SalesByCustomer}
        />
        <Route path="/s/reports/sales-by-user" component={SalesByUser} />
        <Route
          path="/s/reports/purchases-by-operation"
          component={PurchasesByOperation}
        />
        <Route
          path="/s/reports/purchases-by-product"
          component={PurchasesByProduct}
        />
        <Route
          path="/s/reports/purchases-by-category"
          component={PurchasesByCategory}
        />
        <Route
          path="/s/reports/purchases-by-supplier"
          component={PurchasesBySupplier}
        />
        <Route
          path="/s/reports/purchases-by-user"
          component={PurchasesByUser}
        />

        <Route path="/s/reports/customers" component={CustomersReport} />
        <Route path="/s/reports/suppliers" component={SuppliersReport} />
        <Route path="/s/reports/contractors" component={ContractorsReport} />
        <Route path="/s/reports/employees" component={EmployeesReport} />

        <Route path="/s/reports/finance-overview" component={FinanceOverview} />
        <Route path="/s/reports/accounts-balance" component={AccountsBalance} />
        <Route
          path="/s/reports/currencies-balance"
          component={CurrenciesBalance}
        />
        <Route path="/s/reports/transactions" component={TransactionsReport} />
        <Route
          path="/s/reports/accounting-reports"
          component={AccountingReports}
        />
        <Route
          path="/s/reports/accounting-reports-templates/:id"
          component={AccountingTemplateSingle}
        />
        <Route
          path="/s/reports/accounting-reports-templates"
          component={AccountingTemplateList}
        />

        <Route path="/s/reports/overview" component={OverviewReport} />
        <Route path="/s/reports/audit" component={AuditReport} />
        <Route
          path="/s/reports/ongoing-services"
          component={OngoingServicesReport}
        />
        <Route
          path="/s/reports/ending-services"
          component={EndingServicesReport}
        />

        <Route path="/s/reports/files" component={FilesReport} />
        <Route path="/s/reports/cruises" component={CruisesReport} />
        <Route path="/s/reports/car-movement" component={CarMovementReport} />
        <Route path="/s/reports/cars" component={CarsReport} />
        <Route path="/s/reports/balloons" component={BalloonsReport} />

        <Route path="/s/reports" component={ReportsIndex} />

        <Route path="/s/facilities/:id" component={FacilitySingle} />
        <Route path="/s/facilities" component={FacilityList} />
        <Route path="/s/access-groups/:id" component={AccessGroupSingle} />
        <Route path="/s/access-groups" component={AccessGroupList} />
        <Route path="/s/users/:id" component={UserSingle} />
        <Route path="/s/users" component={UserList} />
        <Route path="/s/roles/:id" component={RoleSingle} />
        <Route path="/s/roles" component={RoleList} />
        <Route path="/s/app-settings" component={BaseSettings} />
        <Route path="/s/print-templates/:id" component={PrintTemplateSingle} />
        <Route path="/s/print-templates" component={PrintTemplateList} />
        <Route path="/s/languages/:id" component={LanguageSingle} />
        <Route path="/s/languages" component={LanguageList} />
        <Route path="/s/translations" component={Translations} />
        <Route path="/s/change-password" component={ChangePassword} />
        <Route exact path="/">
          <Home logo={logo} companyName={companyName} />
        </Route>
      </Switch>
    </MainDashboard>
  );
}
