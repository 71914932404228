import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "code",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "sale_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "category",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "categories.name",
  },
];

export default function List() {
  return (
    <ResourceListPage
      url="products"
      columns={columns}
      title="cruise-cabins"
      baseQuery={{
        type: "CRUISE",
      }}
    />
  );
}
