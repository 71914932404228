import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return (
    <ResourceListPage
      url="service-locations"
      title="balloons"
      columns={columns}
      baseQuery={{
        type: "BALLOON",
      }}
    />
  );
}
