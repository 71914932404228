import React, { useMemo } from "react";
import {
  extractCurrencyName,
  ReportViewer,
  useReportController,
  useReportQuery,
} from "commons/components/ReportManager";
import { compose, groupBy, mergeDeepLeft, prop, uniqBy } from "ramda";

const filters = [
  {
    type: "filter",
    name: "service-locations",
    key: "service_location_id",
    extras: { label: "balloons" },
    filters: { type: "BALLOON" },
  },
];

const columns = [
  // purchase_commission: 0
  // purchase_cost: 0
  // purchase_discount: 0
  // purchase_quantity: 1
  // purchase_returned: 0
  // purchase_subtotal: 500000
  // purchase_total: 500000
  // sale_commission: 0
  // sale_cost: 0
  // sale_discount: 0
  // sale_profit: 950000
  // sale_subtotal: 950000
  // sale_total: 950000
  { name: "balloon", type: "text" },
  {
    name: "sale_actual",
    type: "number",
  },
  {
    name: "sale_total",
    type: "money",
  },
  {
    name: "purchase_actual",
    type: "number",
  },
  {
    name: "purchase_total",
    type: "money",
  },
  {
    name: "profit",
    type: "money",
  },
];

const byBalloonID = (record) => record.service_location_id + "";

const salesTransformer = (groupFn, key) => (records) =>
  Object.values(groupBy(groupFn, records)).map((group) => {
    const totals = group.reduce(
      (sum, record, index) => ({
        id: record[key],
        ...sum,
        balloon: record.service_location,
        currency: record.currency,
        service_location_id: record.service_location_id,
        sale_quantity: sum.sale_quantity + Number(record.quantity),
        sale_returned: sum.sale_returned + Number(record.returned),
        sale_actual: sum.sale_actual + Number(record.actual),
        sale_subtotal: sum.sale_subtotal + Number(record.subtotal),
        sale_discount: sum.sale_discount + Number(record.discount),
        sale_total: sum.sale_total + Number(record.total),
        sale_cost: sum.sale_cost + Number(record.cost),
        sale_commission: sum.sale_commission + Number(record.commission),
        sale_profit: sum.sale_profit + Number(record.profit),
      }),
      {
        sale_quantity: 0,
        sale_returned: 0,
        sale_actual: 0,
        sale_subtotal: 0,
        sale_discount: 0,
        sale_total: 0,
        sale_cost: 0,
        sale_commission: 0,
        sale_profit: 0,
      }
    );
    return totals;
  });

const purchasesTransformer = (groupFn, key) => (records) =>
  Object.values(groupBy(groupFn, records)).map((group) => {
    const totals = group.reduce(
      (sum, record, index) => ({
        id: record[key],
        ...sum,
        balloon: record.service_location,
        currency: record.currency,
        service_location_id: record.service_location_id,
        purchase_quantity: sum.purchase_quantity + Number(record.quantity),
        purchase_returned: sum.purchase_returned + Number(record.returned),
        purchase_actual: sum.purchase_actual + Number(record.actual),
        purchase_subtotal: sum.purchase_subtotal + Number(record.subtotal),
        purchase_discount: sum.purchase_discount + Number(record.discount),
        purchase_total: sum.purchase_total + Number(record.total),
        purchase_cost: sum.purchase_cost + Number(record.cost),
        purchase_commission:
          sum.purchase_commission + Number(record.commission),
      }),
      {
        purchase_quantity: 0,
        purchase_returned: 0,
        purchase_actual: 0,
        purchase_subtotal: 0,
        purchase_discount: 0,
        purchase_total: 0,
        purchase_cost: 0,
        purchase_commission: 0,
      }
    );
    return totals;
  });

const arrayToObject = (arr) => {
  return arr.reduce(
    (acc, current) => ({
      ...acc,
      [current.id]: current,
    }),
    {}
  );
};

const salesGroupAndSum = compose(
  arrayToObject,
  salesTransformer(byBalloonID, "service_location_id"),
  uniqBy(prop("id"))
);

const purchasesGroupAndSum = compose(
  arrayToObject,
  purchasesTransformer(byBalloonID, "service_location_id"),
  uniqBy(prop("id"))
);

export default function BalloonsReport() {
  const [serverQuery, query, duration, setQuery, setDuration] = useReportQuery({
    "service_locations.type": "BALLOON",
  });
  const [sales, salesError, salesIsLoading, salesApply] = useReportController(
    "sale-lines",
    duration,
    "sales.date"
  );
  const [purchases, purchasesError, purchasesIsLoading, purchasesApply] =
    useReportController("purchase-lines", duration, "purchases.date");

  const apply = () => {
    salesApply(serverQuery);
    purchasesApply(serverQuery);
  };

  const errors = [salesError, purchasesError].filter((er) => Boolean(er));
  const isLoading = [salesIsLoading, purchasesIsLoading].every((l) => l);

  const records = useMemo(() => {
    const salesByCurrency = groupBy(prop("currency_id"))(sales);
    const purchasesByCurrency = groupBy(prop("currency_id"))(purchases);

    const salesTotals = Object.keys(salesByCurrency).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: salesGroupAndSum(salesByCurrency[curr]),
      };
    }, {});
    const purchasesTotals = Object.keys(purchasesByCurrency).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: purchasesGroupAndSum(purchasesByCurrency[curr]),
        };
      },
      {}
    );

    // const salesTotals = map(salesGroupAndSum, salesByCurrency);
    // const purchasesTotals = map(purchasesGroupAndSum, purchasesByCurrency);

    const totals = mergeDeepLeft(salesTotals, purchasesTotals);

    const result = Object.values(totals).map((currency) =>
      Object.values(currency).map((t) => ({
        ...t,
        profit: (t.sale_total || 0) - (t.purchase_total || 0),
      }))
    );
    return result;
  }, [sales, purchases]);

  const groupsTitles = useMemo(() => {
    return extractCurrencyName(records);
  }, [records]);

  return (
    <ReportViewer
      title="balloonsReport"
      filterByDate
      filters={filters}
      columns={columns}
      records={records}
      groupsTitles={groupsTitles}
      error={errors.length > 0 ? errors[0] : null}
      isLoading={isLoading}
      duration={duration}
      query={query}
      setQuery={setQuery}
      setDuration={setDuration}
      hideIndex
      onApply={apply}
      totals={[
        "sale_actual",
        "purchase_actual",
        "sale_total",
        "purchase_total",
        "profit",
      ]}
    />
  );
}
