import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "id",
    type: "number",
    sortable: true,
    searchable: true,
    searchKey: "transactions.id",
  },
  {
    name: "created",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "creator",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "creators.username",
  },
  {
    name: "approver",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "approvers.username",
  },
  {
    name: "reference",
    type: "text",
    sortable: true,
    searchable: true,
  },
  // {
  //   name: "summary",
  //   type: "text",
  //   sortable: true,
  //   searchable: true,
  // },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="transactions" columns={columns} />;
}
