import React from "react";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { FormDateField } from "commons/components/FormDateField";
import ResourceEditor from "commons/components/ResourceEditor";
import PageCard from "commons/components/PageCard";
import LinkTabs from "commons/components/LinkTabs";
import ResourceToolbar from "commons/components/ResourceToolbar";
import {
  useBalance,
  TotalsGroup,
  TabbedSection,
  OperationsBalance,
  // PaymentsBalance,
} from "commons/components/Balance";
import Stack from "commons/components/Stack";

const base = "cruises";

export default function Single() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/cruises/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/cruises/${id}/balance`,
            component: <Balance id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ send, current, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [ships] = useResourcesByQuery("ships", true, { type: "SHIP" });

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormSelectField
        grid={6}
        options={ships}
        label="ship_id"
        value={model.service_location_id}
        onChange={updateModel("service_location_id")}
        error={rules.service_location_id}
      />
      <FormTextField
        grid={6}
        label="captain"
        value={model.captain}
        onChange={updateModel("captain")}
        error={rules.captain}
      />
      <FormDateField
        grid={6}
        label="starts"
        value={model.starts}
        onChange={updateModel("starts")}
        error={rules.starts}
      />
      <FormDateField
        grid={6}
        label="ends"
        value={model.ends}
        onChange={updateModel("ends")}
        error={rules.ends}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}

function Balance({ id }) {
  const { balance } = useBalance(id, "tourism-balance", "cruises", [
    "sales",
    "purchases",
    "customer-payments",
  ]);

  return (
    <Stack>
      <TotalsGroup
        labels={["sales", "purchases", "profit", "reservations"]}
        values={balance}
      />
      <TabbedSection
        labels={["sales", "purchases"]}
        sections={[
          <OperationsBalance url="sales" filter={{ cruise_id: id }} />,
          <OperationsBalance url="purchases" filter={{ cruise_id: id }} />,
        ]}
      />
    </Stack>
  );
}
