import React, { useMemo, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Grid, useMediaQuery } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { isEmpty, isNil, prop } from "ramda";
import { useTheme } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import { VariableSizeList } from "react-window";

export function DefaultFormSelectField({
  storageKey,
  value,
  onChange,
  options,
  ...props
}) {
  useEffect(() => {
    if (!value) {
      const savedValue =
        JSON.parse(window.localStorage.getItem("default_" + storageKey)) ||
        null;
      if (savedValue && options.find((op) => op.id === savedValue)) {
        onChange(savedValue);
      }
    }
  }, [value, storageKey, onChange, options]);

  const onValueChange = (val) => {
    if (val) {
      window.localStorage.setItem("default_" + storageKey, JSON.stringify(val));
    }
    onChange(val);
  };

  return (
    <FormSelectField
      options={options}
      value={value}
      onChange={onValueChange}
      {...props}
    />
  );
}

export default function FormSelectField({
  options = [],
  optionKey = "id",
  optionLabel = "name",
  grid = 12,
  label,
  value,
  onChange,
  error,
  multiple = false,
  // onItemDataClick = null,
  disableClearable = true,
  ...props
}) {
  const { t } = useTranslate();
  const optionValue = useMemo(() => {
    if (multiple) {
      return value
        ? options.filter((option) => value.includes(option[optionKey]))
        : [];
    } else {
      return options.find((option) => value === option[optionKey]) || null;
    }
  }, [options, optionKey, value, multiple]);

  const nonEmptyOptions = useMemo(() => {
    return options.filter(
      (o) => !isEmpty(o[optionLabel]) && !isNil(o[optionLabel])
    );
  }, [options, optionLabel]);

  return (
    <Grid item sm={grid} xs={12}>
      <Autocomplete
        disableListWrap
        multiple={multiple}
        disableClearable={disableClearable}
        options={nonEmptyOptions}
        getOptionLabel={(option) => `${option[optionLabel] ?? ""}`}
        value={optionValue}
        limitTags={1}
        ListboxComponent={ListboxComponent}
        onChange={(e, newVal) => {
          if (multiple) {
            onChange(newVal.map(prop(optionKey)));
          } else {
            onChange(newVal === null ? null : newVal[optionKey]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(label)}
            error={Boolean(error)}
            helperText={Boolean(error) && t("rules." + error)}
            // InputProps={{
            //   ...params.InputProps,
            //   startAdornment: onItemDataClick ? (
            //     <InputAdornment position="end">
            //       <IconButton size="small" onClick={onItemDataClick}>
            //         <AccountCircle />
            //       </IconButton>
            //     </InputAdornment>
            //   ) : null,
            // }}
          />
        )}
        {...props}
      />
    </Grid>
  );
}

const LISTBOX_PADDING = 4; // px

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;
  const { language } = useTranslate();

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          direction={language === "ar" ? "rtl" : "ltr"}
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
