import React, { useMemo } from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Balance } from "commons/pages/customers/Controller";

const base = "business-partners";

export default function Standalone() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/business-partners/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/business-partners/${id}/balance`,
            component: <Balance id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [policies] = useResourcesByQuery("policies", true);

  const sale_policies = useMemo(() => {
    return policies.filter((pol) => pol.type === "SALES");
  }, [policies]);

  const purchase_policies = useMemo(() => {
    return policies.filter((pol) => pol.type === "PURCHASES");
  }, [policies]);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormSelectField
        grid={4}
        label="sale_policy_id"
        options={sale_policies}
        value={model.sale_policy_id}
        onChange={updateModel("sale_policy_id")}
        error={rules.sale_policy_id}
      />
      <FormSelectField
        grid={4}
        label="purchase_policy_id"
        options={purchase_policies}
        value={model.purchase_policy_id}
        onChange={updateModel("purchase_policy_id")}
        error={rules.purchase_policy_id}
      />
      <FormDateField
        grid={4}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormTextField
        grid={4}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={4}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={4}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}
