import { useMemo } from "react";
import { prop, propEq } from "ramda";
import useQueryAllResources from "commons/hooks/useQueryAllResources";

// const stocksByProduct = (acc, curr) => ({
//   ...acc,
//   [curr.product_id]: [
//     ...(acc[curr.product_id] ? acc[curr.product_id] : []),
//     curr,
//   ],
// });

const settingsReducer = (acc, curr) => ({
  ...acc,
  [curr.key]: curr.value === "true",
});

export default function useFrontDeskData() {
  const [facilities] = useQueryAllResources("facilities");
  const [currencies] = useQueryAllResources("currencies");
  const [policies] = useQueryAllResources("policies");
  const [productPolicies] = useQueryAllResources("policy-product");
  const [locations] = useQueryAllResources("product-service-location");
  const [components] = useQueryAllResources("components");
  const [categories] = useQueryAllResources("categories");
  const [rawSettings] = useQueryAllResources("settings");
  const { discounts, commissions, taxes, employees } = useFrontDeskExtras();

  return {
    facilities,
    currencies,
    policies,
    productPolicies,
    locations,
    components,
    discounts,
    commissions,
    taxes,
    employees,
    categories,
    settings: rawSettings.reduce(settingsReducer, {}),
    //stockLevels.reduce(stocksByProduct, {}),
  };
}

const productPolicyConnect = (products, policies, key) => (item) => ({
  ...item,
  products: products.filter(propEq(key, item.id)).map(prop("product_id")),
  policies: policies.filter(propEq(key, item.id)).map(prop("policy_id")),
});

function useFrontDeskExtras() {
  const [discountRaw] = useQueryAllResources("discounts");
  const [discountProduct] = useQueryAllResources("discount-product");
  const [discountPolicy] = useQueryAllResources("discount-policy");

  const [taxRaw] = useQueryAllResources("taxes");
  const [taxProduct] = useQueryAllResources("product-tax");
  const [taxPolicy] = useQueryAllResources("policy-tax");

  const [employees] = useQueryAllResources("employees");
  const [commissions] = useQueryAllResources("employee-product");

  const discounts = useMemo(
    () =>
      discountRaw.map(
        productPolicyConnect(discountProduct, discountPolicy, "discount_id")
      ),
    [discountRaw, discountProduct, discountPolicy]
  );

  const taxes = useMemo(
    () => taxRaw.map(productPolicyConnect(taxProduct, taxPolicy, "tax_id")),
    [taxRaw, taxProduct, taxPolicy]
  );

  return {
    discounts,
    commissions,
    taxes,
    employees,
  };
}
