import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import Editor from "./Editor";
import ResourceToolbar from "commons/components/ResourceToolbar";
import PageCard from "commons/components/PageCard";
import LinkTabs from "commons/components/LinkTabs";
import Stack from "commons/components/Stack";
import {
  TotalsGroup,
  TabbedSection,
  // PaymentsBalance,
  OperationsBalance,
  useBalance,
} from "commons/components/Balance";

const base = "files";
const initialModel = {
  profit: 0,
};

export default function Single() {
  const { current, send, updateModel, model, id } = useControlledResourcePage(
    base,
    initialModel
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/files/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/files/${id}/balance`,
            component: <Balance id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

function Balance({ id }) {
  const { balance } = useBalance(id, "tourism-balance", "files", [
    "sales",
    "purchases",
    "customer-payments",
  ]);

  return (
    <Stack>
      <TotalsGroup labels={["sales", "purchases", "profit"]} values={balance} />
      <TabbedSection
        // labels={["sales", "purchases", "payments"]}
        labels={["sales", "purchases"]}
        sections={[
          <OperationsBalance url="sales" filter={{ file_id: id }} />,
          <OperationsBalance url="purchases" filter={{ file_id: id }} />,
          // <PaymentsBalance
          //   id={id}
          //   url="customer-payments"
          //   field="customer_id"
          // />,
        ]}
      />
    </Stack>
  );
}
