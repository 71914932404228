import React, { useMemo } from "react";
import ReportManager, {
  groupByCurrency,
} from "commons/components/ReportManager";
import useQueryAllResources from "commons/hooks/useQueryAllResources";

const filters = [
  {
    type: "filter",
    name: "files",
    key: "file_id",
    extras: {
      optionLabel: "name",
    },
  },
];

const columns = [
  {
    name: "number",
    type: "text",
  },
  {
    name: "name",
    type: "text",
  },
  {
    name: "sales_count",
    type: "number",
  },
  {
    name: "sales_avg",
    type: "money",
  },
  {
    name: "sales_total",
    type: "money",
    highlight: true,
  },
  {
    name: "purchases_count",
    type: "number",
  },
  {
    name: "purchases_avg",
    type: "money",
  },
  {
    name: "purchases_total",
    type: "money",
    highlight: true,
  },
  {
    name: "profit",
    type: "money",
    highlight: true,
  },
];

export default function FilesReport() {
  const [currencies] = useQueryAllResources("currencies");

  const lookup = useMemo(
    () =>
      currencies.reduce((a, c) => {
        return {
          ...a,
          [c.id]: c.name,
        };
      }, {}),
    [currencies]
  );

  const extractCurrencyName = (records) => {
    return records.flatMap((c) => {
      return Array.isArray(c) && c.length > 0
        ? lookup[c[0].currency_id]
        : undefined;
    });
  };

  return (
    <ReportManager
      url="tourism-balance"
      title="filesReport"
      columns={columns}
      filters={filters}
      recordsFunc={(recs) => {
        return groupByCurrency(recs);
      }}
      titlesFunc={extractCurrencyName}
      totals={[
        "sales_count",
        "sales_total",
        "purchases_count",
        "purchases_total",
        "profit",
      ]}
      filterByDate
      initialQuery={{ type: "files" }}
    />
  );
}
