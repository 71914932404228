import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormDateField } from "commons/components/FormDateField";
import { FormQuantityField } from "commons/components/FormQuantityField";
import ResourceRelations from "commons/components/ResourceRelations";

const base = "service-locations";

const initialModel = {
  facility_id: 1,
  type: "CAR",
};

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, initialModel, true, "cars");

  return (
    <ResourceSinglePage
      title="cars"
      current={current}
      send={send}
      route="cars"
      accessGroup
    >
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <ReadsManager value={model.reads} onChange={updateModel("reads")} />
    </ResourceSinglePage>
  );
}

function ReadsManager({ value, onChange }) {
  return (
    <ResourceRelations
      title="reads"
      value={value}
      onChange={onChange}
      model={{
        date: null,
        read: "",
        notes: "",
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormDateField
            grid={4}
            label="date"
            value={record.date}
            onChange={onItemChange("date")}
          />
          <FormQuantityField
            grid={4}
            label="odometer_read"
            value={record.read}
            onChange={onItemChange("read")}
          />
          <FormTextField
            grid={4}
            label="notes"
            value={record.notes}
            onChange={onItemChange("notes")}
          />
        </>
      )}
    />
  );
}
