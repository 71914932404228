import { useContext, useMemo } from "react";
import { PermissionsContext } from "commons/helpers/contexts";

export default function usePermissions() {
  const { permissions, user } = useContext(PermissionsContext);

  const permissionByGroup = useMemo(() => {
    return permissions.reduce((acc, curr) => {
      if (acc[curr.access_group_id]) {
        return {
          ...acc,
          [curr.access_group_id]: [...acc[curr.access_group_id], curr.name],
        };
      } else {
        return {
          ...acc,
          [curr.access_group_id]: [curr.name],
        };
      }
    }, {});
  }, [permissions]);

  const hasPermission = (group) => (permission) => {
    return permissionByGroup[group]?.includes(permission);
  };

  return {
    user,
    permissions,
    permissionByGroup,
    hasPermission,
  };
}
