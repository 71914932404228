import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { CropFree } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import useTranslate from "commons/hooks/useTranslate";
import { Importer } from "commons/components/Importer";

const columns = [
  {
    name: "id",
    type: "number",
    sortable: true,
    searchable: true,
    searchKey: "products.id",
  },
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "products.name",
  },
  {
    name: "code",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "supplier_code",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "sale_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "purchase_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "cost_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "type",
    type: "translate",
    sortable: true,
    searchable: true,
    searchList: ["PIECE", "WEIGHT", "VOLUME", "AREA"],
  },
  //   {
  //     name: "threshold",
  //     type: "number",
  //     sortable: true,
  //     searchable: true,
  //   },
  {
    name: "saleable",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "purchasable",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "active",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "category",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "categories.name",
  },
  {
    name: "image",
    type: "image",
    sortable: false,
    searchable: false,
  },
];

export default function List() {
  const { t } = useTranslate();

  return (
    <ResourceListPage
      url="products"
      columns={columns}
      baseQuery={{
        type: {
          $in: ["PIECE", "WEIGHT", "VOLUME", "AREA"],
        },
      }}
      actions={
        <>
          <Tooltip title={t("barcode-printing")}>
            <IconButton component={RouterLink} to={`/s/barcode-printing`}>
              <CropFree />
            </IconButton>
          </Tooltip>
          <Importer
            base="products"
            columns={[
              "id",
              "code",
              "name",
              "sale_price",
              "purchase_price",
              "category_id",
            ]}
            beforeSave={(records) => {
              const recs = records.map((rec) => ({
                ...rec,
                sale_price: Math.floor(rec.sale_price * 100),
                purchase_price: Math.floor(rec.purchase_price * 100),
                type: "PIECE",
              }));
              return recs;
            }}
          />
        </>
      }
    />
  );
}
