import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";

const base = "service-locations";

const initialModel = {
  facility_id: 1,
  type: "SHIP",
};
export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, initialModel, true, "ships");

  return (
    <ResourceSinglePage
      title="ships"
      current={current}
      send={send}
      route="ships"
    >
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
    </ResourceSinglePage>
  );
}
