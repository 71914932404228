import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { Grid, IconButton, Typography } from "@material-ui/core";
import FormSwitch from "commons/components/FormSwitch";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Add, Edit, Remove } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const base = "accounts";
const baseModel = { active: true };

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, baseModel);
  const [accounts] = useResourcesByQuery("accounts", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <Grid item xs sm={4}>
        <AccountTree accounts={accounts} />
      </Grid>
      <Grid item xs sm={8}>
        <Grid container spacing={2}>
          <FormTextField
            grid={3}
            label="code"
            value={model.code}
            onChange={updateModel("code")}
            error={rules.code}
          />
          <FormTextField
            grid={6}
            label="name"
            value={model.name}
            onChange={updateModel("name")}
            error={rules.name}
          />
          <FormSelectField
            grid={3}
            options={accounts}
            label="parent_account_id"
            value={model.parent_account_id}
            onChange={updateModel("parent_account_id")}
            error={rules.parent_account_id}
            disableClearable={false}
          />
          <FormSwitch
            label="active"
            value={model.active}
            onChange={updateModel("active")}
          />
          <FormTextField
            grid={12}
            label="notes"
            value={model.notes}
            onChange={updateModel("notes")}
            error={rules.notes}
          />
        </Grid>
      </Grid>
    </ResourceSinglePage>
  );
}

function AccountTree({ accounts }) {
  //   const [accounts] = useResourcesByQuery("accounts", true);

  //   const handleSelect = (event, nodeIds) => {
  //     // onChange(parseInt(nodeIds));
  //   };

  const history = useHistory();

  const roots = accounts.filter((acc) => acc.parent_account_id === null);

  const onEdit = (id) => {
    history.push(`/s/accounts/${id}`);
  };

  return (
    <TreeView defaultCollapseIcon={<Remove />} defaultExpandIcon={<Add />}>
      {roots.map((acc) => (
        <TreeBranch
          key={acc.id}
          account={acc}
          accounts={accounts}
          onEdit={onEdit}
        />
      ))}
    </TreeView>
  );
}

function TreeBranch({ account, accounts, onEdit }) {
  const myAccounts = accounts.filter(
    (acc) => acc.parent_account_id === account.id
  );

  return (
    <div style={{ background: "rgba(0,0,0,0.025)" }}>
      <TreeItem
        nodeId={`${account.id}`}
        label={
          <div style={{ display: "flex", padding: "8px" }}>
            <Typography variant="subtitle1">
              {`(${account.code}) ${account.name}`}
            </Typography>
            <IconButton
              size="small"
              edge="start"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(account.id);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        }
      >
        {myAccounts.map((acc) => (
          <TreeBranch
            key={acc.id}
            account={acc}
            accounts={accounts}
            onEdit={onEdit}
          />
        ))}
      </TreeItem>
    </div>
  );
}
