import React from "react";
import { Grid } from "@material-ui/core";
import FormSelectField from "commons/components/FormSelectField";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import { assoc, compose } from "ramda";
import FrontDeskController from "commons/pages/sales2/FrontDeskController";

export default function Single() {
  return (
    <FrontDeskController base="purchases" EntityExtras={FileCruiseSelect} />
  );
}

function FileCruiseSelect({ model, onChange, rules }) {
  const [files] = useQueryAllResources("files");
  const [cruises] = useQueryAllResources("cruises");

  const updateFile = (id) => compose(onChange, assoc("file_id", id))(model);
  const updateCruise = (id) => compose(onChange, assoc("cruise_id", id))(model);

  return (
    <Grid container spacing={2}>
      <FormSelectField
        grid={6}
        label="file"
        options={files}
        optionLabel="name"
        value={model.file_id}
        onChange={updateFile}
        error={rules.file_id}
        autoHighlight
      />
      <FormSelectField
        grid={6}
        label="cruise"
        options={cruises}
        value={model.cruise_id}
        onChange={updateCruise}
        error={rules.cruise_id}
        autoHighlight
      />
    </Grid>
  );
}

// function SupplierCard({
//   compact = false,
//   isCreating = false,
//   model,
//   onChange,
//   base,
//   rules,
//   policies = [],
//   products = [],
// }) {
//   const [entities] = useQueryAllResources(base);
//   const [files] = useQueryAllResources("files");
//   const [cruises] = useQueryAllResources("cruises");
//   const [balance, setBalance] = useState(0);

//   const field = useMemo(
//     () => (base === "customers" ? "customer_id" : "supplier_id"),
//     [base]
//   );
//   const defaultKey = useMemo(
//     () => (base === "customers" ? "fdDefaultCustomer" : "fdDefaultSupplier"),
//     [base]
//   );

//   const onEntityChange = useCallback(
//     (id) => {
//       const entity = entities.find((cust) => cust.id === id);
//       if (entity) {
//         compose(
//           onChange,
//           updateLinesPrices(base, products, policies),
//           saveDefaultEntity(defaultKey, field),
//           assoc("policy_id", entity.policy_id),
//           assoc(field, entity.id)
//         )(model);
//       }
//     },
//     [entities, field, model, defaultKey, onChange, base, products, policies]
//   );

//   const entity = useMemo(
//     () => entities.find((rec) => rec.id === model[field]),

//     [entities, field, model]
//   );

//   useEffect(() => {
//     if (entity) {
//       api
//         .service("balance")
//         .get(entity.id, { query: { type: base } })
//         .then((res) => setBalance(res.balance));
//     }
//   }, [entity, base]);

//   useEffect(() => {
//     if (isCreating && !model[field] && !model.policy_id) {
//       const id = parseInt(window.localStorage.getItem(defaultKey)) || null;
//       const entity = entities.find((rec) => rec.id === id);
//       if (entity) {
//         onEntityChange(id);
//       }
//     }
//   }, [isCreating, model, field, entities, defaultKey, onEntityChange]);

//   const showLimit = useMemo(
//     () => entity && entity.credit_limit !== 0,
//     [entity]
//   );

//   useEffect(() => {
//     const handler = (event) => {
//       if (event.data[field]) {
//         onEntityChange(event.data[field]);
//       }
//     };
//     window.addEventListener("message", handler);
//     return () => {
//       window.removeEventListener("message", handler);
//     };
//   }, [onEntityChange, field]);

//   const updateFile = (id) => compose(onChange, assoc("file_id", id))(model);
//   const updateCruise = (id) => compose(onChange, assoc("cruise_id", id))(model);

//   return (
//     <Grid container spacing={2}>
//       <FormSelectField
//         grid={6}
//         label="supplier_id"
//         options={entities}
//         value={model[field]}
//         onChange={onEntityChange}
//         error={rules[field]}
//         autoHighlight
//       />
//       <FormSelectField
//         grid={6}
//         label="file"
//         options={files}
//         optionLabel="name"
//         value={model.file_id}
//         onChange={updateFile}
//         error={rules.file_id}
//         autoHighlight
//       />
//       <FormTextField
//         grid={3}
//         label="credit_limit"
//         value={showLimit ? entity.credit_limit + balance : 0}
//         disabled
//       />
//       <FormTextField
//         grid={3}
//         label="balance"
//         value={showLimit ? balance : 0}
//         disabled
//       />
//       <FormSelectField
//         grid={6}
//         label="cruise"
//         options={cruises}
//         value={model.cruise_id}
//         onChange={updateCruise}
//         error={rules.cruise_id}
//         autoHighlight
//       />
//     </Grid>
//   );
// }
