import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSelectField from "commons/components/FormSelectField";
import FormSwitch from "commons/components/FormSwitch";

const base = "products";

const initialModel = {
  active: true,
  stockable: false,
  purchasable: false,
  saleable: true,
  type: "CRUISE",
  image: "service.png",
};

export default function Single() {
  const { current, send, updateModel } = useControlledResourcePage(
    base,
    initialModel,
    true,
    "cruise-cabins"
  );
  return (
    <ResourceSinglePage
      title="cruise-cabins"
      route="cruise-cabins"
      current={current}
      send={send}
      accessGroup
    >
      <Editor current={current} send={send} updateModel={updateModel} />
    </ResourceSinglePage>
  );
}

function Editor({ current, updateModel }) {
  const { model, rules } = current.context;
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={4}
        label="code"
        value={model.code}
        onChange={updateModel("code")}
        error={rules.code}
      />
      <FormMoneyField
        grid={4}
        label="sale_price"
        value={model.sale_price}
        onChange={updateModel("sale_price")}
        error={rules.sale_price}
      />
      <FormSelectField
        grid={4}
        options={categories}
        label="category_id"
        value={model.category_id}
        onChange={updateModel("category_id")}
        error={rules.category_id}
      />
      <FormTextField
        multiline
        label="description"
        value={model.description}
        onChange={updateModel("description")}
      />
      <FormSwitch
        label="active"
        value={model.active}
        onChange={updateModel("active")}
      />
    </>
  );
}
