import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import { Typography, Grid } from "@material-ui/core";
import {
  ReportCard,
  salesReports,
  purchasesReports,
  accounting,
} from "commons/pages/reports/ReportsIndex";

const misc = [
  { path: "overview", title: "overviewReport" },
  { path: "audit", title: "audit-logs" },
  { path: "ongoing-services", title: "ongoing-services" },
  { path: "ending-services", title: "ending-services" },
];

export const tourism = [
  { path: "files", title: "files" },
  { path: "cruises", title: "cruises" },
  { path: "balloons", title: "balloons" },
  { path: "cars", title: "cars" },
  { path: "car-movement", title: "car-movement" },
];

export const entities = [
  { path: "customers", title: "customers" },
  { path: "suppliers", title: "suppliers" },
  { path: "contractors", title: "contractors" },
  { path: "employees", title: "employees" },
];

export default function ReportsIndex() {
  const { t } = useTranslate();
  return (
    <PageCard>
      <Stack>
        <Typography variant="h4">{t("reports")}</Typography>
        <Grid container spacing={2}>
          <ReportCard reports={salesReports} title={"salesReports"} />
          <ReportCard reports={purchasesReports} title={"purchasesReports"} />
          <ReportCard reports={accounting} title={"accounts"} />
          <ReportCard reports={entities} title={"entities"} />
          <ReportCard reports={tourism} title={"tourism"} />
          <ReportCard reports={misc} title={"misc"} />
        </Grid>
      </Stack>
    </PageCard>
  );
}
