import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";

const base = "service-locations";

const initialModel = {
  facility_id: 1,
  type: "BALLOON",
};

export default function Single() {
  const { current, send, model, updateModel, rules } =
    useControlledResourcePage(base, initialModel, true, "balloons");

  return (
    <ResourceSinglePage
      title="balloons"
      current={current}
      send={send}
      route="balloons"
      accessGroup
    >
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
    </ResourceSinglePage>
  );
}
