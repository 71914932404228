import common from "commons/languages/arabic";

const trans = {
  program_name: "DS Tourism",
  version: "3.3.5",
  cars: "السيارات",
  car: "السيارة",
  ships: "السفن",
  ship: "السفينة",
  ship_id: "السفينة",
  captain: "المدير",
  cruises: "الرحلات",
  passengers: "عدد الركاب",
  number: "الرقم",
  cabins: "عدد الكبائن",
  reservations: "الحجوزات",
  files: "الملفات",
  arrival: "وصول",
  departure: "مغادرة",
  nights: "عدد الليالي",
  guests: "الزبائن",
  nationality: "الجنسية",
  reads: "العداد",
  odometer_read: "القراءة",
  distance: "المسافة",
  cruise: "رحلة",
  file: "ملف",
  filesReport: "تقرير الملفات",
  cruisesReport: "تقرير الرحلات",
  carsReport: "تقرير السيارات",
  balloonsReport: "تقرير البالون",
  tourism: "السياحة",
  sales_count: "عدد ايراد",
  sales_avg: "متوسط ايراد",
  purchases_count: "عدد مصروف",
  purchases_avg: "متوسط مصروف",
  saleable: "للايرادات",
  purchasable: "للمصروفات",
  guest: "الزبون",
  time: "الوقت",
  pickup: "من",
  dropoff: "الي",
  external_car: "سيارة خارجية",
  guide_id: "مرشد",
  guide: "مرشد",
  representative_id: "مندوب",
  representative: "مندوب",
  balloons: "البالون",
  balloon: "البالون",
  CAR_RENTAL: "تأجير السيارات",
  BALLOON_RIDE: "رحلات البالون",
  sale_actual: "عدد ايراد",
  sale_total: "اجمالي ايراد",
  purchase_actual: "عدد مصروف",
  purchase_total: "اجمالي مصروف",
  "balloon-rides": "رحلات البالون",
  "car-rentals": "تأجير السيارات",
  "cruise-cabins": "حجز الرحلات",
  "business-partners": "شراكات",
  "business-partner": "شراكة",
  "tourism-reports": "تقارير السياحة",
  "car-movement": "حركة السيارات",
};

export default { ...common, ...trans };
