import React from "react";
import ReportManager, {
  extractCurrencyName,
  groupByCurrency,
  linesTransformer,
} from "commons/components/ReportManager";
import { compose, map, prop, uniqBy } from "ramda";

const filters = [
  {
    type: "filter",
    name: "service-locations",
    key: "car_id",
    extras: { label: "cars" },
    filters: { type: "CAR" },
  },
];

const columns = [
  { name: "car", type: "text" },
  {
    name: "min_price",
    type: "money",
  },
  {
    name: "avg_price",
    type: "money",
  },
  {
    name: "max_price",
    type: "money",
  },
  {
    name: "actual",
    type: "number",
  },
  {
    name: "discount",
    type: "money",
  },
  {
    name: "total",
    type: "money",
  },
  {
    name: "commission",
    type: "money",
  },
  {
    name: "profit",
    type: "money",
  },
];

const byCar = (record) => record.car_id + "";
const transformer = compose(
  map(linesTransformer(byCar, "car_id")),
  groupByCurrency,
  uniqBy(prop("sale_line_id"))
);

export default function CarsReport() {
  return (
    <ReportManager
      url="car-rentals"
      title="carsReport"
      columns={columns}
      filterByDate
      dateField="sales.date"
      filters={filters}
      recordsFunc={transformer}
      titlesFunc={extractCurrencyName}
      initialQuery={{
        "sale_lines.type": "CAR_RENTAL",
      }}
      totals={["actual", "discount", "total", "commission", "profit"]}
    />
  );
}
